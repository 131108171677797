import main from './main'

export default {
  ...main,

  debug: true,
  baseURL: "https://api.apartments.jarvis.oohdesk.ru",
  
  // baseURL: "https://api.apartments-test.jarvis.oohdesk.ru",
  // analytics: null
}
